
.locale-select {
    max-width: 90px;
    border-radius: 999px !important;
    margin-left: auto;
}
@media (min-width:1200px){
    .locale-select {
        margin-left: 0;
    }
}
