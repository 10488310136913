
// main-auctions-banner
.main-auctions-banner__inner {
    display: block;
}
.main-auctions-banner__con {
    padding-top: 16px;
    padding-bottom: 26px;
}
.main-auctions-banner__img-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
}
.main-auctions-banner__img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.main-auctions-banner__tit {
    font-size: 1.8rem;
    color: var(--v-primary-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-notoserif);
}
.main-auctions-banner__info {
    margin-top: 10px;
}
.main-auctions-banner__info > ul > li {
    display: flex;
    font-size: 1.4rem;
    color: #5a5a5a;
    margin-top: 6px;
}
.main-auctions-banner__info > ul > li:first-child {
    margin-top: 0;
}
.main-auctions-banner__info > ul > li > strong {
    display: inline-block;
    min-width: 30px;
    font-weight: 400;
    color: var(--v-primary-base);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .main-auctions-banner__con {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .main-auctions-banner__tit {
        font-size: 2.4rem;
    }
    .main-auctions-banner__info {
        margin-top: 14px;
    }
    .main-auctions-banner__info > ul > li {
        font-size: 1.6rem;
    }
    .main-auctions-banner__info > ul > li > strong {
        min-width: 40px;
    }
}
@media (min-width: 1200px) {
}
