
// main-text-banner swiper
.main-text-banner .swiper-wrapper{
    align-items: center;
    -webkit-transition-timing-function:linear!important;
    -o-transition-timing-function:linear!important;
    transition-timing-function:linear!important;
}
.main-text-banner .swiper-slide{
    text-align: center;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .main-text-banner{
        overflow: hidden;
        padding: 0 12%;
    }
    .main-text-banner .swiper-container{
        overflow: visible;
    }
}

// main-text-banner
.main-text-banner{
    border-top:1px solid var(--v-primary-base);
    border-bottom: 1px solid var(--v-primary-base);;
}
.main-text-banner__txt{
    display: inline-block;
    font-size: 1.4rem;
    color:var(--v-primary-base);
    text-align: center;
    padding: 16px 10px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .main-text-banner__txt{
        font-size: 1.8rem;
        padding: 26px 19px;
    }
}
@media (min-width:1200px){
}
