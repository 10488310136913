
// main-visual
.main-visual {
    width: 100%;
}
.main-visual__inner {
    position: relative;
    width: 100%;
    height: 100%;
}
.main-visual__img {
    width:100%;
    padding-top: calc(826/1700 * 100%);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
.main-visual__con {
    color: #fff;
    background-color: var(--v-primary-base);
    padding: 30px 20px;
}
.main-visual__tit {
    font-size: 2.2rem;
    font-weight: 400;
    font-family: var(--font-notoserif);
    line-height: 1.2;
}
.main-visual__tit + .main-visual__info {
    margin-top: 14px;
}
.main-visual__info > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px -8px;
}
.main-visual__info > ul > li {
    position: relative;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1;
    padding: 4px 8px;
}
.main-visual__info + .main-visual__btn {
    margin-top: 20px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual__info > ul > li::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, calc(-50% + 1px));
        -ms-transform: translate(0, calc(-50% + 1px));
        transform: translate(0, calc(-50% + 1px));
        display: block;
        width: 1px;
        height: 10px;
        background-color: #fff;
    }
    .main-visual__info > ul > li:first-child::before {
        display: none;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-visual{
        height: 826px;
    }
    .main-visual__img {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        padding-top:0;
    }
    .main-visual__con {
        position: absolute;
        bottom: 50px;
        right: 50px;
        width: 100%;
        max-width: 670px;
        padding: 60px;
    }
    .main-visual__tit {
        font-size: 4.6rem;
    }
    .main-visual__tit + .main-visual__info {
        margin-top: 26px;
    }
    .main-visual__info > ul {
        margin: -6px -12px;
    }
    .main-visual__info > ul > li {
        font-size: 2rem;
        padding: 6px 12px;
    }
    .main-visual__info + .main-visual__btn {
        margin-top: 56px;
    }
}
@media (min-width: 1380px) {
    .main-visual__con {
        right: 100px;
    }
}
